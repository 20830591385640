@use '../../abstracts/' as *;

.e-ButtonBlue,
.e-ButtonBlue:focus(.active),
.e-ButtonBlue:active {
	background-color: $color-btn-blue;
	color: $white;
}

.e-ButtonBlue:not([disabled='']) {
	background: $color-btn-blue-disabled;
	color: $white;
}

.e-ButtonGreen,
.e-ButtonGreen:focus(.active),
.e-ButtonGreen:active {
	background-color: $color-btn-green;
	color: $white;
}

.e-ButtonGreen:not([disabled='']) {
	background: $color-btn-green-disabled;
	color: $white;
}

.e-ButtonOrange,
.e-ButtonOrange:focus(.active),
.e-ButtonOrange:active {
	background-color: $color-btn-orange;
	color: $white;
}

.e-ButtonOrange:not([disabled='']) {
	background: $color-btn-orange-disabled;
	color: $white;
}
// .custom-toolbar-template:before {
// 	content: '\e240'
// }
.custom-toolbar-template {
	width: 100%;
	height: 100px;
	&-left {
		width: 50%;
		float: left;
		.pdfExport:before {
			font-size: 25px;
			content: '\e240';
		}

		.excelExport:before {
			font-size: 25px;
			content: '\e242';
		}
		.csvExport:before {
			font-size: 25px;
			content: '\e241';
		}
		&-labels {
			width: 50%;
			height: 50px;
			float: left;
		}
	}
}
.custom-toolbar-template-right {
	width: 50%;
	float: right;
	&-dialog {
		&-blue {
			.e-dig-content {
				color: blue;
			}
		}
		&-error {
			color: red;
			.e-dig-content {
				color: red;
			}
		}
	}
}
